import * as Maze from './maze';

export class MazeString {

    charList: MazeCharacter[] = []
    width: number = 0

    constructor(
        text_: string,
        public height: number,
    ) { 
        for (let i = 0; i < text_.length; i++) {
            const key = text_.charAt(i)
            const val = CHRS[key.toUpperCase()]
            if (val != null) {
                this.charList.push(val)
                this.width += val.width(height)
            }
        }
        this.width = Math.ceil(this.width) + 1
    }

    get lines(): Maze.MazeLocation[] {
        let x = 0
        let results: Maze.MazeLocation[] = []
        this.charList.forEach(cl => {
            cl.lines(this.height).forEach(l => {
                l = l.translate(x, 0)
                results.push(l)
            })
            x += cl.width(this.height)
        })
        return results
    }

    get size(): Maze.MazeSize {
        return new Maze.MazeSize(this.width, this.height)
    }

    get text(): string {
        return this.charList.map(ch => { return ch.char }).reduce((a, b) => { return a + b })
    }
}

class MazeCharacter {

    constructor(
        public char: string,
        private widthRatio: number,
        private locs: Maze.MazeLocation[],
    ) { }

    width(height: number): number {
        return Math.ceil(height * this.widthRatio)
    }

    lines(height: number): Maze.MazeLocation[] {
        return this.locs.map(l => { return l.multiplay(this.width(height), height - 1) })
    }
}

const CHRS = {
    ' ': new MazeCharacter(' ', 0.5, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(1, 1),
    ]),
    'A': new MazeCharacter('A', 0.8, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.5, 0),
        new Maze.MazeLocation(0.75, 0.45),
        new Maze.MazeLocation(0.25, 0.45),
        new Maze.MazeLocation(0.75, 0.5),
        new Maze.MazeLocation(1, 1),
    ]),
    'B': new MazeCharacter('B', 0.8, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.1, 1),
        new Maze.MazeLocation(0.1, 0),
        new Maze.MazeLocation(0.5, 0),
        new Maze.MazeLocation(0.8, 0.1),
        new Maze.MazeLocation(0.9, 0.2),
        new Maze.MazeLocation(0.8, 0.25 + 0.175),
        new Maze.MazeLocation(0.4, 0.4),
        new Maze.MazeLocation(0.1, 0.4),
        new Maze.MazeLocation(0.5, 0.45),
        new Maze.MazeLocation(0.8, 0.6),
        new Maze.MazeLocation(0.9, 0.7),
        new Maze.MazeLocation(0.8, 0.875),
        new Maze.MazeLocation(0.5, 0.95),
        new Maze.MazeLocation(0.15, 0.95),
        new Maze.MazeLocation(1, 1),
    ]),
    'C': new MazeCharacter('C', 0.9, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.8, 1),
        new Maze.MazeLocation(0.8, 0.8),
        new Maze.MazeLocation(0.5, 1),
        new Maze.MazeLocation(0.2, 0.9),
        new Maze.MazeLocation(0.0, 0.5),
        new Maze.MazeLocation(0.2, 0.1),
        new Maze.MazeLocation(0.5, 0),
        new Maze.MazeLocation(0.8, 0.2),
        new Maze.MazeLocation(0.8, 0.25),
        new Maze.MazeLocation(0.55, 0.05),
        new Maze.MazeLocation(0.2, 0.15),
        new Maze.MazeLocation(0.1, 0.5),
        new Maze.MazeLocation(0.2, 0.85),
        new Maze.MazeLocation(0.5, 0.95),
        new Maze.MazeLocation(0.8, 0.75),
        new Maze.MazeLocation(1, 1),
    ]),
    'D': new MazeCharacter('D', 0.75, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.05, 1),
        new Maze.MazeLocation(0.05, 0),
        new Maze.MazeLocation(0.4, 0),
        new Maze.MazeLocation(0.75, 0.2),
        new Maze.MazeLocation(0.85, 0.5),
        new Maze.MazeLocation(0.75, 0.8),
        new Maze.MazeLocation(0.5, 0.85),
        new Maze.MazeLocation(0.05, 1),
        new Maze.MazeLocation(1, 1),
    ]),
    'E': new MazeCharacter('E', 0.9, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.05, 1),
        new Maze.MazeLocation(0.05, 0.05),
        new Maze.MazeLocation(0.95, 0),
        new Maze.MazeLocation(0.1, 0.05),
        new Maze.MazeLocation(0.1, 0.45),
        new Maze.MazeLocation(0.7, 0.4),
        new Maze.MazeLocation(0.1, 0.45),
        new Maze.MazeLocation(0.1, 1),
        new Maze.MazeLocation(0.95, 0.9),
        new Maze.MazeLocation(1, 1),
    ]),
    'F': new MazeCharacter('F', 0.9, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.05, 1),
        new Maze.MazeLocation(0.05, 0.05),
        new Maze.MazeLocation(0.95, 0),
        new Maze.MazeLocation(0.1, 0.05),
        new Maze.MazeLocation(0.1, 0.45),
        new Maze.MazeLocation(0.7, 0.4),
        new Maze.MazeLocation(0.7, 0.6),
        new Maze.MazeLocation(0.65, 0.6),
        new Maze.MazeLocation(0.65, 0.4),
        new Maze.MazeLocation(0.1, 0.45),
        new Maze.MazeLocation(0.1, 1),
        new Maze.MazeLocation(1, 1),
    ]),
    'G': new MazeCharacter('G', 1, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.8, 1),
        new Maze.MazeLocation(0.8, 0.8),
        new Maze.MazeLocation(0.5, 1),
        new Maze.MazeLocation(0.2, 0.9),
        new Maze.MazeLocation(0.0, 0.5),
        new Maze.MazeLocation(0.2, 0.1),
        new Maze.MazeLocation(0.5, 0),
        new Maze.MazeLocation(0.8, 0.2),
        new Maze.MazeLocation(0.8, 0.25),
        new Maze.MazeLocation(0.55, 0.05),
        new Maze.MazeLocation(0.2, 0.15),
        new Maze.MazeLocation(0.1, 0.5),
        new Maze.MazeLocation(0.2, 0.85),
        new Maze.MazeLocation(0.5, 0.95),
        new Maze.MazeLocation(0.8, 0.75),
        new Maze.MazeLocation(0.5, 0.7),
        new Maze.MazeLocation(0.5, 0.65),
        new Maze.MazeLocation(0.85, 0.65),
        new Maze.MazeLocation(0.85, 1),
        new Maze.MazeLocation(1, 1),
    ]),
    'H': new MazeCharacter('H', 0.7, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.05, 1),
        new Maze.MazeLocation(0.05, 0),
        new Maze.MazeLocation(0.12, 0),
        new Maze.MazeLocation(0.12, 0.5),
        new Maze.MazeLocation(0.85, 0.4),
        new Maze.MazeLocation(0.85, 0),
        new Maze.MazeLocation(0.92, 0),
        new Maze.MazeLocation(0.92, 1),
        new Maze.MazeLocation(1, 1),
    ]),
    'I': new MazeCharacter('I', 0.5, [
        new Maze.MazeLocation(0, 1),
        new Maze.MazeLocation(0.5, 1),
        new Maze.MazeLocation(0.5, 0.07),
        new Maze.MazeLocation(0.3, 0.07),
        new Maze.MazeLocation(0.3, 0),
        new Maze.MazeLocation(0.75, 0),
        new Maze.MazeLocation(0.75, 0.07),
        new Maze.MazeLocation(0.6, 0),
        new Maze.MazeLocation(0.6, 1),
        new Maze.MazeLocation(1, 1),
    ]),
}

const MAZE = [
    // M
    new Maze.MazeLocation(0, 9),
    new Maze.MazeLocation(0, 0),
    new Maze.MazeLocation(4, 4),
    new Maze.MazeLocation(9, 0),
    new Maze.MazeLocation(9, 9),
    // A
    new Maze.MazeLocation(11, 9),
    new Maze.MazeLocation(15, 0),
    new Maze.MazeLocation(17, 4),
    new Maze.MazeLocation(14, 4),
    new Maze.MazeLocation(18, 5),
    new Maze.MazeLocation(20, 9),
    // E
    new Maze.MazeLocation(20, 11),
    new Maze.MazeLocation(11, 11),
    new Maze.MazeLocation(11, 14),
    new Maze.MazeLocation(19, 14),
    new Maze.MazeLocation(19, 15),
    new Maze.MazeLocation(11, 15),
    new Maze.MazeLocation(11, 19),
    new Maze.MazeLocation(20, 19),
    new Maze.MazeLocation(20, 20),
    new Maze.MazeLocation(11, 20),
    // Z
    new Maze.MazeLocation(9, 20),
    new Maze.MazeLocation(0, 20),
    new Maze.MazeLocation(8, 11),
    new Maze.MazeLocation(0, 11),
]

export function mazeMaze(seed: number | null = null): Maze.Maze {
    const drawing = new Maze.MazeDrawing(new Maze.MazeSize(21, 21), MAZE[0], MAZE[MAZE.length - 1], seed)
    drawing.draw(MAZE)
    return drawing.maze
}
